import React from 'react'
import styled from 'styled-components';
import Layout from '../components/Layout'

const ErrorContainer = styled.div`
height: 110vh;
background-image: url('//cdn.dribbble.com/users/156486/screenshots/4952165/space_out.gif');
background-repeat: no-repeat;
background-size: cover;
border-top: 15px solid #ffffff;
`;

const ErrorGroup = styled.div`
height: 80vh;display: flex;flex-direction: column;justify-content: center;
`;

const ErrorHeadingOne  = styled.h1`
font-family: 'Lato', sans-serif;font-weight: 300;font-size: 4.0em;
`

const ErrorHeadingThree  = styled.h3`
color: #ffffff;font-family: 'Merriweather', serif;font-weight: 200;font-size: 2.4em;
`

const ErrorParagraph  = styled.p`
font-family: Lato, sans-serif;font-weight: 300;font-size: 1.0em;
`

const ErrorLink = styled.a`
text-decoration: none;color: #fafafa;position: relative;font-family: 'Lato', sans-serif;font-weight: 300;font-size: 0.8em;
`

const ErrorIconStyle = styled.i`
font-size: 8px;position: absolute;top: 50%;transform: translateY(-50%);
`

const NotFoundPage = () => (
  <Layout>
    <ErrorContainer>
      <div className="container">
        <div className="row">
          <ErrorGroup className="col-md-6">
            <ErrorHeadingOne>Wait a minute</ErrorHeadingOne>
            <ErrorHeadingThree>You
              weren't suppose to see this<br/></ErrorHeadingThree>
            <ErrorParagraph>You found our 404 page. You’ve
              won a trip to space!<br/>
            </ErrorParagraph>
            <ErrorLink href="/">
              <ErrorIconStyle className="fas fa-chevron-right"/> Not really
            </ErrorLink>
          </ErrorGroup>
          <div className="col"/>
        </div>
      </div>
    </ErrorContainer>
  </Layout>
)

export default NotFoundPage
